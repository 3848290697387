import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import NumericInput from "../../components/NumericInput";
import { selectHeaderData } from "../header/headerSlice";
import {
  get2ADataVerifyCatchaV2,
  verify2ADataCatcha,
} from "../../api/2adataAPI";
import UpdateCredentials from "./UpdateCredentials";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import OtpVerifiyForCaptcha from "../../components/profile/OtpVerifiyForCaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes  } from '@fortawesome/free-solid-svg-icons';

const SyncProcessFailNotification = (props) => {
  const {
    company_id,
    year,    
    handleClose,
    refreshSupplierList,
    syncStatusFailureMsg
  } = props;

  const headerData = useSelector(selectHeaderData);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaId, setCaptchaId] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [gstportalUsername, setGstportalUsername] = useState("");
  const [getCaptchaData, setGetCaptchaData] = useState({});
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [otpRequired, setOtpRequired] = useState(false)

  const getCaptcha = () => {
    async function fetchEverything() {
      try {
        const result = await get2ADataVerifyCatchaV2(company_id);
        setCaptchaImg(result.data.captcha_image);
        setCaptchaId(result.data.gpc_id);
        setOpenSetPassword(!result.data.gstportal_isverified);
        // setOpenSetPassword(true);
        setGstportalUsername(result.data.gstportal_username);
        setGetCaptchaData(result.data);
        setCaptcha("");
      } catch (err) {
        console.error("error: ", err);
        setError(err.message);
      } finally {
        setCaptchaLoading(false);
        setLoadingFirst(false);
      }
    }
    setCaptchaLoading(true);
    fetchEverything();
  };

  const loadCaptcha = () => {
    setError(null);
    setCaptchaLoading(true);
    getCaptcha();
  };

  const verifyCatpcha = (props) => {
    if (!props?.otp) {
      if (captcha.length === 0) {
        setError("Please enter captcha!");
        return;
      }
      if (captcha.length < 6) {
        setError("Please enter valid captcha!");
        return;
      }
    }
    const data = {
      gpc_id: captchaId,
      ...(props?.otp ? { otp: props.otp } : { captcha_text: captcha }),
      company_id,
      priority: "GST2A",
      year,
    };

    async function fetchEverything() {
      async function fetch2AData() {
        const result = await verify2ADataCatcha(data);

        let return_filling_status_data = localStorage.getItem(
          "return-filling-status-data-v4"
        );
        return_filling_status_data = return_filling_status_data
          ? JSON.parse(return_filling_status_data)
          : [];
        let current_filling_return_data = return_filling_status_data.filter(
          (return_data, index) => {
            return return_data.gst_number !== headerData.gst_number;
          }
        );
        localStorage.setItem(
          "return-filling-status-data-v4",
          JSON.stringify(current_filling_return_data)
        );

        handleClose(false);
        refreshSupplierList(result.data.message, true);
      }
      try {
        await Promise.all([fetch2AData()]);
        setError(null);
        setOtpRequired(false)
        handleClose()
      } catch (err) {
        if (err?.status === 423) {
          setOtpRequired(true)
        } else {
          //handleClose()
        }
        if (err) {
          setError(err?.data?.errors[0])
        }
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const handleCloseOtp = () => {
    setOtpRequired(false);
    getCaptcha()
  }

  useEffect(() => {
    getCaptcha();
    document.getElementById("captcha").focus();
    if (syncStatusFailureMsg !== "") {
      setError(syncStatusFailureMsg);
    }
    //eslint-disable-next-line   
  }, [company_id]);


  return (
    <>
      {loadingFirst ? <LoadingIndicator /> : null}
     
      {openSetPassword ? (
          <UpdateCredentials
            company_id={company_id}
            headerData={headerData}
            gstportalUsername={gstportalUsername}
            getCaptchaData={getCaptchaData}
            getCaptcha={getCaptcha}
            handleClose={handleClose}
            refreshSupplierList={refreshSupplierList}
            verifyCaptch={true}
          />
        ) : (
          <>
          <div className="gstheader_synccardhoverbox header_syncbubble_clickhidebox">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="media">
                  <div className="media-body">
                    <p className="gstheader_synccard_contentbox">
                      Oops! It seems like the sync failed. This can happen due to congestion at GSTN Portal or logging to GSTN Portal during the sync..
                    </p>
                    <p className="gstheader_synccard_gstinportal_textfs">Please enter captcha to restart</p>
                  </div>
                  <div className="gstheader_synccard_closeboxicon ml-1" style={{ width: '10px' }} onClick={handleClose}>
                    <span className="">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="card gstheader_synccardboxcetcha">
                  <div className="row">
                    <div className="form-group col-md-7 mb-0">
                      <div className="gstheader_synccard_captchaimg captcha_img">
                        {!captchaLoading ? (
                          <img
                            src={`data:image/png;base64, ${captchaImg}`}
                            alt=""
                          />
                        ) : (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </div>
                    
                      <div className=" form-group col-md-5" >
                        <NumericInput
                          className="form-control"
                          style={{boxShadow:'none', fontSize:'0.72rem'}}
                          id="captcha"
                          placeholder="Enter Captcha"
                          value={captcha}
                          maxLength={6}
                          onChange={(event) => setCaptcha(event.target.value)}
                          autoComplete="new-password"
                        />
                      </div>
                    
                    <div className="col-sm-12 text-left">
                      <p className="gstheader_synccard_captchatextfs">
                        <small style={{ fontSize: '12px' }}>
                          Can't read the captcha?
                          <Button
                            variant="link"
                            onClick={() => loadCaptcha()}
                            style={{ margin: "-9px", fontSize: 12, verticalAlign: 'baseline' }}
                          >
                            Click here
                          </Button>
                          to refresh
                        </small>
                      </p>
                    </div>
                    <div className="col-sm-12 text-left">
                      <p className="gstheader_synccard_progresstextfs mb-0">
                        Do not login to GSTN Portal during this progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center pt-2">
                {error ? <Alert variant="danger">{error}</Alert> : null}
                <Button
                  className="btn btn-default gstheader_synccard_goit_btn bookVerifyBtn"
                  id="zps_proceed_portal"
                  onClick={verifyCatpcha}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>

              </div>
            </div>
          </div>
          </>
        )}
       
      {otpRequired && <OtpVerifiyForCaptcha handleClose={handleCloseOtp} callFunc={verifyCatpcha} error={error} setError={setError} />}

    </>
  );
};

export default SyncProcessFailNotification;
