import React, { useEffect, useState } from 'react';
import './CredentialsPopup.scss';
import { faEye, faEyeSlash, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eWayBillStartSyncUsingConnector, getCaptchaForEWBFromConnector, initiateVerifyEWayPortalLogin } from '../../api/localConnectorAPI';
import Spinner from "react-bootstrap/Spinner";
import checkIcon from "../../images/billcheckicon.png";
import closeIcon from "../../images/billclose.png";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { eWayBillStartSync, eWayBillSyncLoginRequest } from '../../api/serverConnectorAPI';
import ShowAlert from '../../components/ShowAlert';
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from '../../app/constants';


const EWayCredentialsPopup = (props) => {

    const { port, ewbUserName, ewbUserPassword, company_id, isEwbVerified, gspCredsData, setGspErrObj, startGspSync } = props;
    const nPattern = new RegExp(/^[0-9]*$/);

    const [loading, setLoading] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);
    const [credsData, setCredsData] = useState({ username: ewbUserName, password: ewbUserPassword });
    const [errObj, setErrObj] = useState({});
    const [captchaLoading, setCaptchaLoading] = useState(false)
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [captchaImg, setCaptchaImg] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaRef, setCaptchaRef] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [otp, setOtp] = useState({
        otp_text: "",
        showOtp: false,
        resendOtp: false
    });


    const getCaptchaFunc = async () => {

        setCaptcha('');
        setCaptchaLoading(true);
        try {
            const result = await getCaptchaForEWBFromConnector(port);
            if (result?.data) {
                setCaptchaImg(result?.data?.captcha_image ?? "");
                setCaptchaRef(result?.data?.captcha_ref ?? "")
            }
        } catch (err) {
            console.error('error: ', err);
            setError(err.message);
        } finally {
            setCaptchaLoading(false);
        }
    };   

    const handleSubmit = () => {
        const errorObject = {};  // For user credentials (credsData)
        const gspErrObj = {};    // For GSP credentials (gspCredsData)

        let gspNameCheck = !gspCredsData?.username || gspCredsData?.username?.trim()?.length === 0;
        let gspPswrdCheck = !gspCredsData?.password || gspCredsData?.password?.trim()?.length === 0;
        let credsUsername = !credsData?.username || credsData?.username?.trim()?.length === 0;
        let credsPassword = !credsData?.password || credsData?.password?.trim()?.length === 0;
        let credsCaptcha = !captcha || captcha?.trim()?.length === 0;


        // Validate GSP credentials:
        if (gspCredsData?.password?.trim()?.length > 0 && (gspNameCheck)) {
            gspErrObj.username = "Please enter the username";  // Only show error for username if password is filled
        }

        if (gspCredsData?.password?.trim()?.length > 0 && gspCredsData?.username?.length < 8 && gspCredsData?.username?.length > 0) {
            gspErrObj.username = "Please check username again, min 8 characters are needed"; 
        }

        if (gspCredsData?.username?.trim()?.length > 0 && (gspPswrdCheck)) {
            gspErrObj.password = "Please enter the password";  // Only show error for password if username is filled
        }

        if (gspCredsData?.username?.trim()?.length > 0 && gspCredsData?.password?.length < 8 && gspCredsData?.password?.length > 0) {
            gspErrObj.password = "Please check password again, min 8 characters are needed"; 
        }

        //Validate EWB credentials:
        if ((credsUsername) && credsData?.password?.trim()?.length > 0 && captcha?.trim()?.length > 0) {
            errorObject.username = "Please enter the username";  //Only show error for username if password & captcha is filled
        }

        if (credsData?.username?.length > 8 && credsData?.username?.length < 8 && credsData?.password?.trim()?.length > 0 && captcha?.trim()?.length > 0) {
            errorObject.username = "Please check username again, min 8 characters are needed";
        }

        if ((credsPassword) && credsData?.username?.trim()?.length > 0 && captcha?.trim()?.length > 0) {
            errorObject.password = "Please enter the password"; //Only show error for password if username & captcha is filled
        }

        if (credsData?.password?.length > 0 && credsData?.password?.length < 8 && credsData?.username?.trim()?.length > 0 && captcha?.trim()?.length > 0) {
            errorObject.password = "Please check password again, min 8 characters are needed";
        }
        
        if ((credsCaptcha) && credsData?.username?.trim()?.length > 0 && credsData?.password?.trim()?.length > 0) {
            errorObject.captcha = "Please enter the captcha"; //Only show error for captcha if username & password is filled
        }

        if (credsData?.username?.trim()?.length > 0 && (credsPassword) && (credsCaptcha)) {
            errorObject.password = "Please enter the password"; 
            errorObject.captcha = "Please enter the captcha"; 
        }

        if (credsData?.password?.trim()?.length > 0 && (credsUsername) && (credsCaptcha)) {
            errorObject.username = "Please enter the username"; 
            errorObject.captcha = "Please enter the captcha"; 
        }
        
        if (captcha?.trim()?.length > 0 && (credsUsername) && (credsPassword)) {
            errorObject.username = "Please enter the username"; 
            errorObject.password = "Please enter the password"; 
        }

        if (gspNameCheck && gspPswrdCheck && credsUsername && credsPassword && credsCaptcha) {  // If all fields are empty
            gspErrObj.username = "Please enter the username";
            gspErrObj.password = "Please enter the password"; 
            errorObject.username = "Please enter the username";  
            errorObject.password = "Please enter the password";
            errorObject.captcha = "Please enter the captcha"; 
        }

        // Validate OTP if it's shown and resend OTP isn't triggered
        if (otp.showOtp && !otp.resendOtp && otp?.otp_text?.trim()?.length === 0) {
            errorObject.otp_text = "Please enter the OTP";
        }

        // If there are any errors, do not proceed with the API calls
        if (Object.keys(errorObject).length > 0) setErrObj(errorObject);
        if (Object.keys(gspErrObj).length > 0) setGspErrObj(gspErrObj);


        if (gspCredsData?.password?.trim()?.length > 0 && gspCredsData?.username?.trim()?.length > 0 && Object.keys(gspErrObj).length === 0) {
            startGspSync(company_id, gspCredsData?.username ?? "", gspCredsData?.password ?? "");
            setGspErrObj({}); 
        } else if (credsData?.password?.trim()?.length > 0 && credsData?.username?.trim()?.length > 0 && captcha?.trim()?.length > 0 && Object.keys(errorObject).length === 0) {
            requestLoginForSync();  
            setErrObj({}); 
        } else if (gspCredsData?.password?.trim()?.length > 0 && gspCredsData?.username?.trim()?.length > 0 && Object.keys(errorObject).length === 0 && credsData?.password?.trim()?.length > 0 && credsData?.username?.trim()?.length > 0 && captcha?.trim()?.length > 0 && Object.keys(gspErrObj).length === 0) {
            startGspSync(company_id, gspCredsData?.username ?? "", gspCredsData?.password ?? "");  
            requestLoginForSync("stopAtLogin");
            setGspErrObj({}); 
            setErrObj({});
        }
    };
    

    const requestLoginForSync = async (checkKey) => {
        setLoading(true);

        const postData = {
            company_id: company_id,
            captcha_ref: captchaRef,
            captcha_text: captcha,
            ewb_username: credsData?.username ?? "",
            ewb_password: credsData?.password ?? ""
        }

        if (otp.showOtp && otp?.otp_text?.trim()?.length > 0) {
            postData.otp = otp.otp_text;
        }

        if (otp.resendOtp) {
            postData.resend_otp = true;
        }

        try {
            const result = await eWayBillSyncLoginRequest(postData);
            const responseData = result.data ?? null;
            const responseHeader = result.headers ?? null;
            if (otp.resendOtp) {
                otp.resendOtp = false;
                setOtp({ ...otp });
            };
            if (Number(responseData?.status === 1)) {
                const connectorRequest = {
                    data: responseData?.data,
                };

                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                try {
                    const response = await initiateVerifyEWayPortalLogin(port, connectorRequest, extraHeader);
                    if (response?.data) {
                        if (checkKey === "stopAtLogin") {
                            return;
                        }
                        if (response.message === "ewb.otp_required") {
                            setCaptchaRef(response?.data?.captcha_ref ?? "");
                            setSuccess(CONNECTOR_ERRORS_MAPPING[response?.message] ?? "");
                            otp.showOtp = true;
                            setOtp({ ...otp });
                        } else {
                            setSyncLoading(true);
                            startSyncUsingConnector(response?.data?.captcha_ref);

                        }
                    }
                } catch (err) {
                    setError(CONNECTOR_ERRORS_MAPPING[err?.message] ? CONNECTOR_ERRORS_MAPPING[err?.message] : err?.message ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
                    if (otp.showOtp) return; // If OTP is displayed, return early to prevent an unnecessary CAPTCHA API call, as CAPTCHA is not required in this case.
                    setCaptcha("");
                    getCaptchaFunc();
                }
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    const startSyncUsingConnector = async (captcha_ref) => {

        const postData = {
            company_id: company_id,
            captcha_ref: captcha_ref,
            captcha_text: captcha,
        };

        try {
            const result = await eWayBillStartSync(postData);
            const responseData = result.data ?? null;
            const responseHeader = result.headers ?? null;
            if (Number(responseData.status === 1)) {
                const connectorRequest = {
                    data: responseData?.data,
                };

                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                try {
                    const response = await eWayBillStartSyncUsingConnector(port, connectorRequest, extraHeader);
                    if (Number(response?.status) === 1) {
                        window.location.reload();
                        // When the user starts the sync process for the first time, an error message appears below the cards 
                        // on the eWayBillSummary page (e.g., "Enter credentials to view data").
                        // Since the cards are displayed in a different component, we used window.location.reload 
                        // to remove the error once the sync starts and refresh the data.
                    };
                } catch (err) {
                    setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
                    setCaptcha("");
                    getCaptchaFunc();
                    setOtp({});
                }
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setSyncLoading(false);
        }
    };

    useEffect(() => {
        getCaptchaFunc();
        //eslint-disable-next-line
    }, []);


    return (

        <>

            {(loading || syncLoading) ? <LoadingIndicator /> : null}

            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null) }}
            />

            <fieldset className="gsteway_GSPCredentials_fs gsteway_GSPCredentials_fsborderblue mt-0">
                <legend className="gsteway_GSPCredentials_leg mb-0">
                    <div className="gsteway_GSPCredentials_inndev">
                        <div className="gsteway_GSPCredentials_leftside mb-0">
                            <h4>Add Login details of ewaybillgst.gov.in</h4>
                        </div>
                        <div className="gsteway_GSPCredentials_innerhieghtdiv"></div>
                        <div className="gsteway_GSPCredentials_rightsidediv">
                            <span className="gsteway_GSPCredentials_billiconbox">
                                <img
                                    src={isEwbVerified ? checkIcon : closeIcon}
                                    width="22" alt='details'
                                />
                            </span>
                        </div>
                        <div className="gsteway_GSPCredentials_middlebor gsteway_GSPCredentials_middleborderblue"></div>
                    </div>
                </legend>

                <div className="row">
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <label for="" className="gsteway_Addinfo_bill_label">Username<span className="red_text">*</span></label>
                        <input
                            type="text"
                            className="form-control gsteway_Addinfo_bill_grpinput"
                            placeholder="Enter Username"
                            value={credsData?.username ?? ""}
                            onChange={(e) => setCredsData({ ...credsData, username: e.target.value })}
                            maxLength={20}
                        />
                        <span className='red_text'>{errObj?.username ?? ""}</span>
                    </div>
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <label for="" className="gsteway_Addinfo_bill_label">Password<span className="red_text">*</span></label>

                        {showPassword ?
                            <div className="input-group">
                                <input
                                    type="input"
                                    className="form-control gsteway_Addinfo_bill_passinput"
                                    placeholder="Enter Password"
                                    value={credsData?.password ?? ""}
                                    onChange={(e) => setCredsData({ ...credsData, password: e.target.value })}
                                />
                                <div className="input-group-prepend gsteway_Addinfo_bill_prependbox_eyeicon" onClick={() => setShowPassword(false)}>
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                </div>
                            </div>
                            :
                            <div className="input-group">
                                <input
                                    type="password"
                                    className="form-control gsteway_Addinfo_bill_passinput"
                                    placeholder="Enter Password"
                                    value={credsData?.password ?? ""}
                                    onChange={(e) => setCredsData({ ...credsData, password: e.target.value })}
                                />
                                <div className="input-group-prepend gsteway_Addinfo_bill_prependbox_eyeicon" onClick={() => setShowPassword(true)}>
                                    <FontAwesomeIcon icon={faEye} />
                                </div>
                            </div>
                        }
                        <div>
                            <span className='red_text'>{errObj?.password ?? ""}</span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6 text-center">
                        {!captchaLoading ?
                            ( <div className="d-flex align-items-center">
                                <img
                                    src={`data:image/png;base64, ${captchaImg}`}
                                    alt='captcha' width="90%"
                                />
                                <span>
                                    <FontAwesomeIcon icon={faSync} onClick={() => {
                                        setCaptcha("");
                                        getCaptchaFunc();
                                        if(Object.keys(otp)?.length > 0) setOtp({});
                                    }} className="refrsh_sync" />
                                </span>
                                </div>
                            ) :
                            (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )
                        }
                    </div>
                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6 mt-2">
                        {otp.showOtp &&
                            <>
                                <input
                                    type="text"
                                    className="form-control gsteway_Addinfo_bill_grpinput"
                                    placeholder="Enter OTP in case of 2Way auth"
                                    value={otp.otp_text ?? ""}
                                    onChange={(e) => {
                                        if (nPattern.test(e.target.value)) {
                                            otp.otp_text = e.target.value ?? "";
                                            setOtp({ ...otp })
                                        }

                                    }}
                                />
                                <span className='red_text'>{errObj?.otp_text ?? ""}</span>
                            </>
                        }
                    </div>

                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-6">
                        <input
                            type="text"
                            className="form-control gsteway_Addinfo_bill_grpinput"
                            placeholder="Enter Captcha"
                            value={captcha ?? ""}
                            onChange={(e) => setCaptcha(e.target.value)}
                        />
                        <span className='red_text'>{errObj?.captcha ?? ""}</span>
                    </div>

                    {otp.showOtp && <div className="form-group gsteway_Addinfo_bill_grp col-sm-6 text-center">
                        <button className="gsteway_Addinfo_bill_submitbtn mt-1"
                            onClick={() => {
                                otp.resendOtp = true;
                                otp.otp_text = "";
                                setOtp({ ...otp });
                                setTimeout(() => handleSubmit(), 100);
                            }}
                        >
                            Resend OTP
                        </button>
                    </div>}

                    <div className="form-group gsteway_Addinfo_bill_grp col-sm-12 pt-2 text-center">
                        <button className="gsteway_Addinfo_bill_submitbtn" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>

            </fieldset>

        </>
    )
}

export default EWayCredentialsPopup