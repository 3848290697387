import React from 'react';
import Modal from "react-bootstrap/Modal";
import './GSPNeedHelpPopup.scss';
import nicgspvideo from "../../images/NICGSPLoginCreation.mp4";
import kinowbaseimg from '../../images/knowbase.png';



const GSPNeedHelpPopup = (props) => {

    const { show, onHide } = props;

    return (
        <>
            <Modal className='ewayGSP_knowledgevideo_modal' backdrop="static" show={show} onHide={onHide}>
                <Modal.Header closeButton className="ewayGSP_knowledgevideo_header">
                    <Modal.Title className="ewayGSP_knowledgevideo_titlefs" >
                        Need Help
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-2 px-2 pb-2'>
                    <div className='row'>
                        <div className='col-md-9 text-center'>
                            <div className='card ewayGSP_knowledgevideo_cardbox'>
                                <video class="w-100" controls autoplay>
                                    <source src={nicgspvideo} type="video/mp4" />
                                </video>
                            </div>

                        </div>
                        <div className='col-md-3 text-center'>
                            <div className='ewayGSP_knowledgevideo_iconcardbox'>
                                <a href='https://support.kdksoftware.com/portal/en/kb/articles/nic-gsp-login-creation-process' target='_self' className='ewayGSP_knowledgevideo_iconboxlink'>
                                    <img src={kinowbaseimg} width="100%" min-width="200" className='ewayGSP_knowledgevideo_imagwidth' alt='media' />
                                    <p className='ewayGSP_knowledgevideo_textfs pt-4'>
                                        Want to read step by step process. Click here!!
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

};


export default GSPNeedHelpPopup