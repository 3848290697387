/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import {
  Formik, Form,
} from 'formik';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import './LoginScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import RegisterImage from '../../images/loginicon/Register_Image.png';
import Indiaflagexp from '../../images/loginicon/India-flag.jpg';
import CA_Blue from '../../images/paln_CA_img_blue.png';
import SME_Blue from '../../images/user_end_sme_blue.png';
import CA_White from '../../images/paln_CA_img_white.png';
import SME_White from '../../images/user_end_sme_white.png';
import NumericInput from '../../components/NumericInput';
import exclamantion from '../../images/exclamation.png';
import ShowAlert from '../../components/ShowAlert';
import { isAlreadyExistEmailOrMobile, sendOTPForSignUp, signUpUser } from '../../api/authenticationAPI';
import ErrorFocus from '../../components/ErrorFocus';
import Verify from './Verify';
import { BASE_PATH } from '../../app/constants';
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import { conversionAdwordsTracking_code as signup_btn_Trck } from '../../utils/UtilityFunctions';
import { SIGNUP_AVAILABLE, DEFAULT_UTM_SOURCE, COBRAND_UTM_SOURCE } from '../../app/constants';
import { salesiq_to_crm, initializeSalesIq } from './visitor_info.js';
import { encryptPasswordString } from '../../utils/UtilityFunctions';
import { updateAuthToken } from '../../utils/AuthToken';

const atLeasetDigit = new RegExp(/(?=.*\d)[\S]{1,}$/);
const atLeasetUpperCase = new RegExp(/(?=.*[A-Z])[\S]{1,}$/);
const atLeasetLowerCase = new RegExp(/(?=.*[a-z])[\S]{1,}$/);
// const atLeasetSpecial = new RegExp(/(?=.*[@$!%*?&])[\S]{1,}$/);
const atLeasetEight = new RegExp(/[\S]{8,}$/);
const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
const org_regex = new RegExp(/[A-Za-z0-9 .&_/-]+$/);
const password_regex = new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/);

const WhatsAppMobileMessage = "OTP will be sent on this whatsapp number for Verification"
const RegiteredEmailMessage = "Enter correct email & verify, this will be used for Account recovery"

export const signUpFormValidationSchema = Yup.object().shape({
  mobile_no: Yup.number()
    .required('This field is required')
    .min(5999999999, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),
  email: Yup.string().trim().required('This field is required').email('Invalid Email'),
  full_name: Yup.string()
    .required('This field is required')
    .max(75, 'Maximum 75 characters allowed')
    .matches(/[A-Za-z0-9 .&_/-]+$/, 'Invalid Name'),
  organization_name: Yup.string()
    .required('This field is required')
    .max(75, 'Maximum 75 characters allowed')
    .matches(/[A-Za-z0-9 .&_/-]+$/, 'Invalid Organization / Firm Name'),
  password: Yup.string().trim()
    .required('This field is required')
    .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/, 'Invalid Password'),
  password_confirmation: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  organization_type: Yup.string()
    .required('This field is required'),
  pincode: Yup.number()
    .required('This field is required')
    .min(100000, 'Valid Indian Pin (Postal) code is required')
    .max(999999, 'Valid Indian Pin (Postal) code is required'),
});

const validateInput = (name, values, setFieldError) => {
  if (name === "mobile_no") {
    if (!String(values[name] ?? "")) setFieldError(name, "This field is required");
    else if (values[name] < 5999999999 || values[name] > 9999999999) setFieldError(name, "Invalid Mobile Number");
  } else if (name === "email") {
    if (!values[name]) setFieldError(name, "This field is required");
    else if (!emailRegex.test(values[name])) setFieldError(name, "Invalid Email");
  } else if (name === "full_name") {
    if (!values[name]) setFieldError(name, "This field is required");
    else if (values[name]?.length > 75) setFieldError(name, "Maximum 75 characters allowed");
  } else if (name === "organization_name") {
    if (!values[name]) setFieldError(name, "This field is required");
    else if (!org_regex.test(values[name])) setFieldError(name, "Invalid Organization / Firm Name");
    else if (values[name]?.length > 75) setFieldError(name, "Maximum 75 characters allowed");
  } else if (name === "password") {
    if (!values[name]) setFieldError(name, "This field is required");
    else if (!password_regex.test(values[name])) setFieldError(name, "Invalid Password");
  } else if (name === "password_confirmation") {
    if (!values[name]) setFieldError(name, "This field is required");
    else if (values[name] !== values["password"]) setFieldError(name, "Passwords must match");
  } else if (name === "organization_type") {
    if (!values[name]) setFieldError(name, "This field is required");
  } else if (name === "pincode") {
    if (!values[name]) setFieldError(name, "This field is required");
    else if (values[name] < 100000 || values[name] > 999999) setFieldError(name, "Valid Indian Pin (Postal) code is required");
  }
}

const SignUpScreen = ({ history }) => {

  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);

  // Generate Sign In Page URL
  let loginUrl = `${BASE_PATH}login`;
  // If we are not in a Cobrand and utm_source is passed via Query params, then use that
  if (!COBRAND_UTM_SOURCE && params.utm_source) {
    loginUrl += `?utm_source=${params.utm_source}`;
  }

  // In case of Cobrand, we need to first check whether Signup is available or not.
  // If not available, we should redirect back to Login Page
  if (!SIGNUP_AVAILABLE) {
    history.push(loginUrl);
  }


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [signUpData, setSignUpData] = useState({
    otp_verification_id: '',
    country_code: '+91',
    mobile_no: '',
    email: '',
    full_name: '',
    organization_name: '',
    password: '',
    password_confirmation: '',
    utm_source: COBRAND_UTM_SOURCE ?? DEFAULT_UTM_SOURCE ?? '', // First priority to Cobrand (if exists)
    organization_type: '',
    pincode: '',
    terms_and_conditions: true,
  });

  const [showTandC, setShowTandC] = useState(false);
  const [mobileExist, setMobileExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const sendSignUpOTP = async (values) => {
    setLoading(true);
    signup_btn_Trck();
    salesiq_to_crm(values.full_name, values.email, values.mobile_no, values.organization_type, values.pincode);

    //encrypt password strings
    //values['password'] = encryptPasswordString(values['password']);
    //values['password_confirmation'] = encryptPasswordString(values['password_confirmation']);

    setSignUpData({ ...signUpData, ...values });
    const data = {
      country_code: values.country_code,
      mobile_no: values.mobile_no,
      email: values.email,
      organization_type: values.organization_type,
      organization_name: values.organization_name,
      pincode: values.pincode
    };

    try {
      const result = await sendOTPForSignUp(data);
      setIsOTPSent(true);
      setSignUpData({ ...values, otp_verification_id: result.data.otp_verification_id });
      setSuccess(result?.message);
    } catch (err) {
      setError(err.message);
      console.error('Error in send otp: ', err);
    }
    setLoading(false);
  };

  const signUp = async (data) => {
    setLoading(true);

    //encrypt password strings
    data['password'] = encryptPasswordString(data['password']);
    data['password_confirmation'] = encryptPasswordString(data['password_confirmation']);

    try {
      const result = await signUpUser(data);
      setSuccess(result.message);
      updateAuthToken(result?.data?.token);
      localStorage.setItem('signup', true);
      setTimeout(() => {
        history.push(`${BASE_PATH}`);
      }, 4000);
    } catch (err) {
      setError(err.message);
      console.error('Error in send otp: ', err);
    }
    setLoading(false);
  };

  // const isUserExit = async (data, name, setFieldError) => {
  //   const payload = {
  //     country_code: "+91",
  //     mobile_no: (name === 'mobile_no') ? data.mobile_no : "",
  //     email: (name === 'email') ? data.email : ""   
  //   }

  //   try {
  //     const result = await checkUserActive(payload);
  //     if(Number(result?.data ?? 0) === 1){
  //       if(name === "mobile_no") setFieldError(name, "Already registered number");
  //       if(name === "email") setFieldError(name , "Already registered email")
  //      }else{
  //       if(name === "mobile_no") setFieldError(name, "");
  //       if(name === "email") setFieldError(name , "")
  //     }
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  useEffect(() => {
    const newParams = {};
    Object.keys(params).map((paramKey) => {
      // In case of Cobrand with available utm_source, it cannot be over-written by Query params
      if (COBRAND_UTM_SOURCE && paramKey === 'utm_source') {
        return;
      }

      if (Array.isArray(params[paramKey])) {
        newParams[paramKey] = params[paramKey][0];
      } else {
        newParams[paramKey] = params[paramKey];
      }
    });
    setSignUpData({ ...signUpData, ...newParams });
  }, [searchStr]);

  useEffect(() => {
    initializeSalesIq();
  }, []);


  const checkAlreadyExistMobileOrEmail = async (type, value, setFieldError) => {
    try {
      const payload = {
        country_code: '+91',
        mobile_no: type === 'mobile_no' ? value : '',
        email: type === 'email' ? value : '',
      }
      const result = await isAlreadyExistEmailOrMobile(payload);
      if (type === 'mobile_no') {
        setMobileExist(false);
        if (Number(result?.data ?? 0) === 1) {
          setFieldError(type, 'Already registered number');
          setMobileExist(true);
        }
      } else if (type === 'email') {
        setEmailExist(false);
        if (Number(result?.data ?? 0) === 1) {
          setFieldError(type, 'Already registered email id');
          setEmailExist(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }


  const handleValdationOnBlur = (e, setFieldError, values) => {

    const { name, value } = e.target;

    if (value.trim().length === 0) {
      setFieldError(name, 'This field is required');
    } else {

      if (name === 'mobile_no' && (Number(value) < 5999999999 || Number(value) > 9999999999)) {
        setFieldError('mobile_no', 'Invalid Mobile Number');
      } else if (name === 'mobile_no' && (Number(value) > 5999999999 && Number(value) < 9999999999)) {
        checkAlreadyExistMobileOrEmail('mobile_no', value, setFieldError);
      }


      if (name === 'email') {
        var mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!mailformat.test(value)) {
          setFieldError('email', 'Invalid Email');
        } else {
          checkAlreadyExistMobileOrEmail('email', value, setFieldError);
        }
      }

      if (name === 'password') {
        var passwordformat = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/
        if (!passwordformat.test(value)) {
          setFieldError('password', 'Invalid Password');
        }
      }

      if (name === 'password_confirmation') {
        const password = values?.password ?? '';
        if (password !== value) {
          setFieldError('password_confirmation', 'Passwords must match');
        }
      }
    }
  }

  return (
    <div>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <TermsAndConditionsPopup show={showTandC} handleClose={() => setShowTandC(false)} />
      {isOTPSent ? (
        <Verify
          mobile_no={signUpData.mobile_no}
          otp_verification_id={signUpData.otp_verification_id}
          handleProceed={(otpVerificationId) => {
            setSignUpData({ ...signUpData, otp_verification_id: otpVerificationId });
            signUp({ ...signUpData, otp_verification_id: otpVerificationId });
          }}
          resendOTP={() => sendSignUpOTP(signUpData)}
          setError={setError}
        />
      ) : (
        <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-2">

          <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
          <div className="col-xs-12 col-lg-12 col-md-12 col-sm-12 login-container mt-28 vertical-center">

            <div className="row">
              <div className=" col-md-6 mt-2">
                <p className="">
                  <span className="fileetdssm" style={{ color: ' #31719b' }}>
                    Sign Up for Free Trial
                  </span>

                </p>
              </div>
              {/* <div className=" col-md-6 mt-2 pr-0">
                  <span className="btn btn-default float-right backhbtnls">
                    <a href="https://www.expressgst.com/">  Back to Home</a>
                  </span>
                </div> */}

            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-12  col-xs-4 col-sm-4 col-md-4 login-img-caontainer">
                <img className="" src={RegisterImage} alt="ExpressGST Sign Up!" ng-click="close()" />
              </div>

              <div className=" login-form-container col-xs-12 col-sm-12 col-xs-8 col-sm-8 col-md-8 bg-whitecolor formBorder">
                <Formik
                  initialValues={signUpData}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={signUpFormValidationSchema}
                  onSubmit={(values) => {
                    // same shape as initial values
                    //console.log('Submit values: ', values);
                    sendSignUpOTP(values);
                  }}
                  validate={values => {
                    const errors = {};
                    if (mobileExist) {
                      errors.mobile_no = 'Already registered number'
                    }
                    if (emailExist) {
                      errors.email = 'Already registered email id'
                    }
                    return errors;
                  }}
                  enableReinitialize
                >
                  {({
                    errors, values, setFieldValue, setFieldError
                  }) => {
                    if (Object.keys(errors).length > 0) {
                      //console.log('Form Errors: ', errors);
                    }

                    return (
                      <Form key={1} autoComplete="off" className="form-horizontal logforms">
                        <ErrorFocus />

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="text"
                                  className={`form-control lg-input inputplace inputplace1 ${errors.full_name ? "input_error_message" : ""}`}
                                  name="Name"
                                  placeholder="Enter Full Name*"
                                  required=""
                                  value={values.full_name}
                                  maxLength={75}
                                  onChange={(e) => {
                                    setFieldValue('full_name', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('full_name', '');
                                    }
                                  }}
                                  disabled={signUpData.okey}
                                  //onBlur={() => validateInput("full_name", values, setFieldError)}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                />
                                {errors.full_name ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.full_name}
                                </span> : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="text"
                                  className={`form-control lg-input inputplace inputplace1 ${errors.organization_name ? "input_error_message" : ""}`}
                                  name="organization_name"
                                  placeholder="Enter Organization / Firm Name*"
                                  required=""
                                  value={values.organization_name}
                                  maxLength={75}
                                  onChange={(e) => {
                                    setFieldValue('organization_name', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('organization_name', '');
                                    }
                                  }}
                                  disabled={signUpData.okey}
                                  //onBlur={() => validateInput("organization_name", values, setFieldError)}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                />
                                {errors.organization_name ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.organization_name}
                                </span> : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <div className="input-group">
                                  <div className={`input-group-prepend inpgpbx ${errors.mobile_no ? "mobileflagiput-error" : ""}`}>
                                    <img className="flag_icon" src={Indiaflagexp} alt="mobile" style={{ paddingRight: '4px', position: 'relative', left: '0px' }} />
                                  </div>
                                  <NumericInput
                                    type="tel"
                                    className={`form-control lg-input inputplace inputplace1 ${errors.mobile_no ? "input_error_message" : ""}`}
                                    name="mobile_no"
                                    placeholder="Enter WhatsApp No*"
                                    maxLength={10}
                                    value={values.mobile_no}
                                    onFocus={(e) => e.target["valueBeforeFocus"] = e.target.value}
                                    onChange={(e) => {
                                      setFieldValue('mobile_no', e.target.value);
                                      if (e.target.value.trim().length > 0) {
                                        setFieldError('mobile_no', '');
                                      }
                                    }}
                                    disabled={signUpData.okey}
                                    onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                  // onBlur={(e) => {
                                  //   validateInput("mobile_no", values, setFieldError);
                                  //   if(e.target["valueBeforeFocus"] !== e.target.value) isUserExit(values, "mobile_no", setFieldError);
                                  // }}

                                  />
                                </div>
                                {errors.mobile_no ? <span className="col-sm-10 signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.mobile_no}
                                </span>
                                  : <span style={{ fontSize: '11px', fontWeight: 'normal', fontStyle: 'italic', color: '#31719b', paddingTop: '5px' }}>
                                    {WhatsAppMobileMessage}
                                  </span>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="email"
                                  className={`form-control lg-input inputplace inputplace1 ${errors.email ? "input_error_message" : ""}`}
                                  name="email"
                                  placeholder="Enter Email ID*"
                                  required=""
                                  onFocus={(e) => e.target["valueBeforeFocus"] = e.target.value}
                                  value={values.email}
                                  //onChange={(e) => setFieldValue('email', e.target.value)}
                                  onChange={(e) => {
                                    setFieldValue('email', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('email', '');
                                    }
                                  }}
                                  disabled={signUpData.okey}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                // onBlur={(e) => {
                                //   validateInput("email", values, setFieldError);
                                //   if(e.target["valueBeforeFocus"] !== e.target.value) isUserExit(values, "email", setFieldError);
                                // }}

                                />
                                {errors.email ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.email}</span> : 
                                  <span style={{ fontSize: '11px', fontWeight: 'normal', fontStyle: 'italic', color: '#31719b', paddingTop: '5px' }}>
                                    {RegiteredEmailMessage}
                                  </span>
                                }
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                {showPassword ? (
                                  <div className="input-group tooltipshowoh">
                                    <input
                                      type="input"
                                      className={`form-control lg-input inputplace inputplace1 ${errors.password ? "input_error_message" : ""}`}
                                      name="password"
                                      placeholder="Enter Password*"
                                      required=""
                                      onFocus={() => setShowPasswordTooltip(true)}
                                      onBlur={(e) => {
                                        setShowPasswordTooltip(false);
                                        //validateInput("password", values, setFieldError)
                                        handleValdationOnBlur(e, setFieldError, values)
                                      }}
                                      value={values.password}
                                      maxLength={100}
                                      onChange={(e) => {
                                        setFieldValue('password', e.target.value);
                                        if (e.target.value.trim().length > 0) {
                                          setFieldError('password', '');
                                        }
                                      }}

                                    />
                                    <div className={`input-group-prepend eyeipbox ${errors.password ? "mobileflagiput-error" : ""}`} onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="input-group tooltipshowoh">
                                    <input
                                      type="password"
                                      className={`form-control lg-input inputplace inputplace1 ${errors.password ? "input_error_message" : ""}`}
                                      name="password"
                                      placeholder="Enter Password*"
                                      required=""
                                      onFocus={() => setShowPasswordTooltip(true)}
                                      onBlur={(e) => {
                                        setShowPasswordTooltip(false);
                                        //validateInput("password", values, setFieldError)
                                        handleValdationOnBlur(e, setFieldError, values)
                                      }}
                                      value={values.password}
                                      onChange={(e) => {
                                        setFieldValue('password', e.target.value);
                                        if (e.target.value.trim().length > 0) {
                                          setFieldError('password', '');
                                        }

                                      }}
                                      autoComplete="new-password"
                                      maxLength={100}
                                    />
                                    <div className={`input-group-prepend eyeipbox ${errors.password ? "mobileflagiput-error" : ""}`} onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faEye} />
                                    </div>
                                  </div>
                                )}

                                {showPasswordTooltip && (
                                  <div className="toolbox">
                                    <div className="passlist">
                                      <p className="passmust">Password Must</p>
                                      <span>
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetDigit.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have One Number
                                      </span>
                                      <br />
                                      <span>
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetUpperCase.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have One Uppercase Character
                                      </span>
                                      <br />
                                      <span>
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetLowerCase.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have One Lowercase Character
                                      </span>
                                      { /* <br />
                                <span>
                                  <span className="passlisticon">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={atLeasetSpecial.test(values.password) ? 'green' : ''}
                                    />
                                  </span>
                                  Have One Special Character
                                </span> */ }
                                      <span>
                                        <br />
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetEight.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have 8 Characters minimum
                                      </span>

                                    </div>
                                  </div>
                                )}
                                {errors.password ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.password}</span> : null}
                              </div>
                            </div>

                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2 mb-0">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <div className="input-group">
                                  <input
                                    type="password"
                                    className={`form-control lg-input inputplace inputplace1 ${errors.password_confirmation ? "input_error_message" : ""}`}
                                    name="password"
                                    placeholder="Enter Confirm Password*"
                                    required=""
                                    value={values.password_confirmation}
                                    onChange={(e) => {
                                      setFieldValue('password_confirmation', e.target.value);
                                      if (e.target.value.trim().length > 0) {
                                        setFieldError('password_confirmation', '');
                                      }

                                    }}
                                    autoComplete="new-password"
                                    maxLength={100}
                                    //onBlur={() => validateInput("password_confirmation", values, setFieldError)}
                                    onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}

                                  />
                                  {/* <div className="input-group-prepend eyeipbox">
                                <FontAwesomeIcon icon={faEye} />
                            </div> */}
                                </div>
                                {errors.password_confirmation ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.password_confirmation}</span> : null}
                              </div>

                            </div>
                          </div>
                        </div>
                        {/* --select catg field add--- */}
                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="number"
                                  className={`form-control lg-input pincode ${errors.pincode ? "input_error_message" : ""}`}
                                  name="pincode"
                                  id="pincode"
                                  placeholder="Enter Pin Code*"
                                  required=""
                                  value={values.pincode}
                                  minLength={6}
                                  maxLength={6}
                                  onChange={(e) => {
                                    setFieldValue('pincode', e.target.value);
                                    //if(errors["pincode"]) setFieldError("pincode", "");
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('pincode', '');
                                    }
                                  }}
                                  //onBlur={() => validateInput("pincode", values, setFieldError)}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}

                                />
                                {errors.pincode ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.pincode}</span> : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-xs-12 col-sm-12 col-md-12 ">
                            <div className="form-group form-group2 mt-2 mb-1">
                              <label className="selectcatgfs">Select Organization / Firm Type<span className='red_text'>*</span></label>
                              <div className="row organization_box">
                                <div className="col-xs-6 col-sm-6 col-md-6">

                                  <div className="col-xs-12 col-sm-12 col-md-12 pr-2" onClick={(e) => setFieldValue('organization_type', 'CA')}>
                                    <div className="usersignupradiobox_client">
                                      <input type="radio" className="form-check-input outline_focus" name="Industry"
                                        checked={values.organization_type === 'CA' ? "checked" : ""}
                                        value="CA&#x2f;CS&#x2f;Tax&#x20;Professionals"
                                        onChange={(e) => {
                                          setFieldValue('industry_type', e.target.value);
                                          if (errors["industry_type"]) setFieldError("industry_type", "");
                                        }}
                                        onBlur={() => validateInput("industry_type", values, setFieldError)}
                                        onFocus={() => {
                                          setFieldValue('organization_type', 'CA')
                                        }}
                                      />
                                    </div>
                                    <div className={values.organization_type === 'CA' ? "usertypouterbox active" : "usertypouterbox"}>
                                      <div className="media">
                                        <img src={values.organization_type === 'CA' ? CA_White : CA_Blue} width="22" alt="CA" className="align-self-center mr-2" />
                                        <div className="media-body">
                                          <label className="mb-0">

                                            <span className="catextfs active">
                                              I am a Professional (CA, Accountant, Tax Practitioner, GSTP)
                                              &amp;  Filing Returns for Clients
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6">
                                  <div className="col-xs-12 col-sm-12 col-md-12 ml-4" onClick={(e) => setFieldValue('organization_type', 'CORPORATE')}>
                                    <div className="usersignupradiobox_bussiness">
                                      <input type="radio" className="form-check-input outline_focus" name="Industry"
                                        checked={values.organization_type === 'CORPORATE' ? "checked" : ""}
                                        value="Corporate&#x2f; SME"
                                        onChange={(e) => {
                                          setFieldValue('industry_type', e.target.value);
                                          if (errors["industry_type"]) setFieldError("industry_type", "");
                                        }}
                                        onBlur={() => validateInput("industry_type", values, setFieldError)}

                                      />
                                    </div>
                                    <div className={values.organization_type === 'CORPORATE' ? "usertypouterbox active" : "usertypouterbox"}>
                                      <div className="media">
                                        <img src={values.organization_type === 'CORPORATE' ? SME_White : SME_Blue} width="22" alt="CORPORATE" className="align-self-center mr-2" />
                                        <div className="media-body">
                                          <label className="mb-0">
                                            <span className="catextfs active">
                                              I am a Business (SME, Corporate, Enterprise)
                                              &amp; Filing Return for Business / Group Business
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {errors.organization_type ? <span className="signup_error_msg">
                                  <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                                  {errors.organization_type}</span> : null}
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* --select catg field add--- */}
                        <div className="form-group height-40 mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                            <input
                              type="checkbox"
                              className="outline_focus"
                              name="login"
                              required=""
                              checked={values.terms_and_conditions}
                              onChange={(e) => setFieldValue('terms_and_conditions', !values.terms_and_conditions)}
                            />
                            <span className="pl-1 keeplogin">
                              {' '}
                              I agree to the
                              {' '}
                              <Button
                                variant="link"
                                onClick={() => setShowTandC(true)}
                                style={{
                                  padding: '0px', fontSize: '14px', display: 'contents',
                                  fontWeight: '500'
                                }}
                              >
                                Terms & Conditions
                              </Button>
                            </span>

                          </div>
                        </div>

                        <div className="form-group mb-2">
                          <div className="col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center">
                            <Button
                              type="submit"
                              bsPrefix="form-control btn red-btn semi-bold btnFont"
                              name="Sign Up"
                              disabled={!values.terms_and_conditions || loading}
                              style={{ width: '234px' }}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  {' '}
                                  Loading...
                                </>
                              ) : 'SIGN UP'}
                            </Button>

                          </div>
                        </div>

                        <div className="col-sm-12 col-xs-8 col-sm-12 col-md-12 col-md-offset-2 text-center padding-25 mtn-37">
                          <p className="ng-binding alsintextsiz">
                            <span className="pr-2">Already have an account?</span>
                            <Link className="sinintext" to={loginUrl}>Sign In</Link>
                          </p>
                        </div>

                      </Form>
                    );
                  }}
                </Formik>

              </div>

            </div>
          </div>
          <div className="col-sm-2 login-empty-container" />

        </div>
      )}
    </div>

  );
};

export default SignUpScreen;
