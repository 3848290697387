//#### Utility Functions - Manage Local Storage Items ####

//### header sync notification - GotItFlag - IN_PROGRESS status
export function getItem_gotItFlag(gstNumber, mobileNumber) {
  return localStorage.getItem("gotItFlag" + gstNumber + mobileNumber);
}

export function setItem_gotItFlag(gstNumber, mobileNumber, value) {
  return localStorage.setItem("gotItFlag" + gstNumber + mobileNumber, value);
}

export function removeItem_gotItFlag(gstNumber, mobileNumber) {
  return localStorage.removeItem("gotItFlag" + gstNumber + mobileNumber);
}


//### header sync notification - bubbleSuccessMsgFlag - SUCCESS status
export function getItem_bubbleSuccessMsgFlag(gstNumber, mobileNumber) {
  return localStorage.getItem("bubbleSuccessMsgFlag" + gstNumber + mobileNumber);
}

export function setItem_bubbleSuccessMsgFlag(gstNumber, mobileNumber, value) {
  return localStorage.setItem("bubbleSuccessMsgFlag" + gstNumber + mobileNumber, value);
}

export function removeItem_bubbleSuccessMsgFlag(gstNumber, mobileNumber) {
  return localStorage.removeItem("bubbleSuccessMsgFlag" + gstNumber + mobileNumber);
}


//### header sync notification - gstrClientFirstSync - First Time Sync - IN_PROGRESS status
export function getItem_gstrClientFirstSync(gstNumber, mobileNumber) {
  return localStorage.getItem('gstrClientFirstSync-' + gstNumber + '-' + mobileNumber);
}

export function setItem_gstrClientFirstSync(gstNumber, mobileNumber, value) {
  return localStorage.setItem('gstrClientFirstSync-' + gstNumber + '-' + mobileNumber, value);
}

export function removeItem_gstrClientFirstSync(gstNumber, mobileNumber) {
  return localStorage.removeItem('gstrClientFirstSync-' + gstNumber + '-' + mobileNumber);
}

