/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import NumericInput from '../../components/NumericInput';
import ShowAlert from '../../components/ShowAlert';
import loginleftside from '../../images/loginicon/login_left_side.png';
import exclamantion from '../../images/exclamation.png';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import { loginUser } from '../../api/authenticationAPI';
import { BASE_PATH } from '../../app/constants';
import { getRedirectUrl, removeRedirectUrl } from '../../utils/UtilityFunctions';
import { selectAppData, setAppData } from '../../app/appDataSlice';
import { COBRAND_UTM_SOURCE, SIGNUP_AVAILABLE } from '../../app/constants';
import { salesiq_chatoff, initializeSalesIq } from './visitor_info.js';
import './LoginScreen.css';
import { encryptPasswordString } from '../../utils/UtilityFunctions';
import { updateAuthToken } from '../../utils/AuthToken';
import './LoginScreen.css';
import TwoFactorAuthVerify from './TwoFactorAuthVerify.jsx';


const LoginScreen = ({ history }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  // Generate Sign Up Page URL
  let signupUrl = `${BASE_PATH}signup`;
  // If we are not in a Cobrand and utm_source is passed via Query params, then use that
  if (!COBRAND_UTM_SOURCE && params.utm_source) {
    signupUrl += `?utm_source=${params.utm_source}`;
  }

  const [mobileNo, setMobileNo] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [authOTP, setAuthOTP] = useState('');

  const appData = useSelector(selectAppData);
  const dispatch = useDispatch();

  const validateInput = (name) => {
    if (name === "mobile_no") {
      if (!String(mobileNo)) formErrors[name] = "This field is required";
      else if (mobileNo < 5999999999 || mobileNo > 9999999999) formErrors[name] = "Invalid Mobile Number";
    } else if (name === "password") {
      if (!String(password)) formErrors[name] = "This field is required";
    }
    setFormErrors({ ...formErrors });
  };
 
  const login = async (e) => {
    e.preventDefault();
    const formErr = {};
    if (!String(mobileNo)) formErr.mobile_no = "This field is required";
    if (mobileNo < 5999999999 || mobileNo > 9999999999) {
      formErr.mobile_no = 'Invalid Mobile Number';
    }
    if (password?.trim()?.length === 0) {
      formErr.password = 'This field is required';
    }
    setFormErrors(formErr);
    if (Object.keys(formErr).length > 0) return;

    let data = {
      country_code: '+91',
      mobile_no: mobileNo,
      password: encryptPasswordString(password),
    };

    if (authOTP) {
      data['mfa_otp'] = authOTP
    }

    try {
      setLoading(true);
      const result = await loginUser(data);
      if (parseInt(result?.data?.mfa_status ?? 0) === 1) {
          setAuthOTP('');
          setShowOtpPopup(true);
      } else {
        setSuccess(result.message); salesiq_chatoff();
        updateAuthToken(result?.data?.token);
        localStorage.removeItem('address_available'); //remove old value if exist
        if (result?.data?.address_available === false) {
          localStorage.setItem('address_available', false);
        }
        let redirect_url = getRedirectUrl();
        if (redirect_url) {
          removeRedirectUrl();
          redirect_url += redirect_url.includes('?') ? '&isLogin=true' : '?isLogin=true';
          history.push(redirect_url);
        }
        else {
          history.push(`${BASE_PATH}?isLogin=true`);
        }
      }
    } catch (err) {
      setAuthOTP('');
      setError(err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    initializeSalesIq();
  }, []);

  useEffect(() => {
    if (appData.authError) {
      setError(appData.authError);
      setTimeout(() => {
        dispatch(setAppData({ authError: "" }));
      }, 3000);
    }
  }, [appData.authError]);


  return (
    <div>
      {error && <ShowAlert
        error={error}
        success={success}
        onClose={() => {
          setError(null); setSuccess(null);
        }}
      />}

      {showOtpPopup &&
        <TwoFactorAuthVerify
          show={showOtpPopup}
          handleClose={() => {
            setShowOtpPopup(false)
            setLoading(false);
          }}
          setAuthOTP={setAuthOTP}
          handleSuccess={(e) => login(e)}
          authOTP={authOTP}
        />
      }

      <div className="container clear-padd mt-2">

        <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
        <div className="col-xs-8 col-lg-8 col-md-12 col-sm-12 login-container mt-28 vertical-center">

          <div className="row">
            <div className=" col-md-8 mt-2">
              <p className="">
                <span className="fileetdssm" style={{ color: '#f27b72' }}> GST Compliance made hassle-free</span>

              </p>
            </div>
            {/* <div className=" col-md-4 mt-2 pr-0">
              <span className="btn btn-default float-right backhbtnls">
                <a href="https://www.expressgst.com/">  Back to Home</a>
              </span>
            </div> */}

          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12  col-xs-6 col-sm-6 col-md-6 login-img-caontainer">
              <img className="" src={loginleftside} alt="mobile" ng-click="close()" />
            </div>

            <div className=" login-form-container col-xs-12 col-sm-12 col-xs-6 col-sm-6 col-md-6 bg-whitecolor formBorder">

              <h2 className="mt-45 col-md-offset-1 loginHeader ng-binding ml-0 text-center" style={{ color: '#31719b' }}>Welcome to Express GST</h2>
              <h5 className="mt-42 col-md-offset-1 font-16 ng-binding subtitle ml-0 text-center">Login with your Mobile Number</h5>
              <form className="form-horizontal logforms" onSubmit={(e) => login(e)}>
                <div className="form-group form-group2">
                  <div className="col-xs-10 col-sm-10 col-md-10 login-form-input-box">

                    <div className="input-group">
                      <div className={`input-group-prepend inpgpbx ${formErrors.mobile_no ? "mobileflagiput-error" : ""}`}>
                        {/* <i className="far fa-flag-india"> </i> */}
                        <img className="flag_icon" src={Indiaflagexp} alt="mobile" style={{ paddingRight: '6px', position: 'relative', left: '-3px' }} />
                      </div>
                      <NumericInput
                        type="tel"
                        className={`form-control lg-input inputplace inputplace1 ${formErrors.mobile_no ? "input_error_message" : ""}`}
                        name="Mobile"
                        placeholder="Enter Mobile Number"
                        required=""
                        value={mobileNo}
                        maxLength={10}
                        onChange={(e) => {
                          setMobileNo(e.target.value)
                          if (formErrors["mobile_no"]) {
                            formErrors["mobile_no"] = "";
                            setFormErrors({ ...formErrors });
                          }
                        }}
                        onBlur={() => validateInput("mobile_no")}
                      />

                    </div>
                    {formErrors.mobile_no ? <span className="signup_error_msg">
                      <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                      {formErrors.mobile_no}</span> : null}
                  </div>
                </div>
                <div className="form-group form-group2">
                  <div className="col-xs-10 col-sm-10 col-md-10 login-form-input-box">
                    {showPassword ? (
                      <div className="input-group">
                        <input
                          type="input"
                          className={`form-control lg-input inputplace inputplace1 ${formErrors.password ? "input_error_message" : ""}`}
                          name="password"
                          placeholder="Enter Password"
                          required=""
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (formErrors["password"]) {
                              formErrors["password"] = "";
                              setFormErrors({ ...formErrors });
                            }
                          }}
                          onBlur={() => validateInput("password")}

                        />
                        <div className={`input-group-prepend eyeipbox ${formErrors.password ? "mobileflagiput-error" : ""}`} onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                      </div>
                    ) : (
                      <div className="input-group">
                        <input
                          type="password"
                          className={`form-control lg-input inputplace inputplace1 ${formErrors.password ? "input_error_message" : ""}`}
                          name="password"
                          placeholder="Enter Password"
                          required=""
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (formErrors["password"]) {
                              formErrors["password"] = "";
                              setFormErrors({ ...formErrors });
                            }
                          }}
                          autoComplete="new-password"
                          onBlur={() => validateInput("password")}

                        />
                        <div className={`input-group-prepend eyeipbox ${formErrors.password ? "mobileflagiput-error" : ""}`} onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                      </div>
                    )}

                    {formErrors.password ? <span className="signup_error_msg">
                      <img src={exclamantion} alt="exclamantion icon" className="excltionicon" />
                      {formErrors.password}</span> : null}
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-xs-10 col-sm-10 col-md-10 col-md-offset-3 login-form-input-box">

                    <Button
                      type="submit"
                      bsPrefix="form-control btn red-btn semi-bold btnFont"
                      name="Login"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'LOGIN'}
                    </Button>
                  </div>
                </div>

                <div className="col-sm-12 col-xs-8 col-sm-12 col-md-12 col-md-offset-2 text-center padding-25 mtn-37">
                  <p className="ng-binding alsintextsiz">
                    <span className="">
                      <Link className="regtext" to={`${BASE_PATH}forgetpass?mobile_no=${mobileNo}`}>Forgot Password?</Link>
                    </span>

                  </p>
                </div>
                {SIGNUP_AVAILABLE ?
                  (<div className="col-sm-12 col-xs-8 col-sm-12 col-md-12 col-md-offset-2 text-center padding-25 mtn-37">
                    <p className="ng-binding alsintextsiz">
                      <span className="pr-2">Don't have an account?</span>
                      <Link className="regtext" to={signupUrl}>Sign Up</Link>
                    </p>
                  </div>)
                  : null}

              </form>
            </div>

          </div>
        </div>
        <div className="col-sm-2 login-empty-container" />

      </div>
    </div>

  );
}

export default LoginScreen;
