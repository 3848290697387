import { getAuthToken } from "./AuthToken";


// Plan Feature Codes
export const PLNFEAT_PAN_LEVEL_EINVOICE_IMP = "imp_panlvleiv_xl";
//EWB DIY features 
export const PLNFEAT_EWB_DIY = "dshbrd_ewb_r1"
//EWB SYNC API
export const PLNFEAT_EWB_SYNC_API = "sync_ewb_api"
//EWB SYNC PORTAL
export const PLNFEAT_EWB_SYNC_PORTAL = "sync_ewb_prtl"
export const PLNFEAT_RECO_RATE = "purch_reco_rate";
export const RECO_MANY_TO_MANY = "purch_reco_lnk_m";
export const EDIT_TOLERANCE_LIMIT = "purch_reco_tol";
export const MULTI_FY_ENABLED = "purch_reco_selmy";
export const PURCHASE_RECO_IMPORT_PAN = "purch_reco_imp_p";
export const PURCHASE_RECO_SUPPLIER_MAIL = "purch_reco_em_s";



export function getUserData() {
  try {
    const authToken = getAuthToken(true);
    const decodedString = atob(authToken.split(".")[1]); // Decoding the token - to decode the base64-encoded string
    const decodedData = JSON.parse(decodedString); // Check if the decoded string is an object or an array
    const decodedSubData = JSON.parse(decodedData?.sub);
    if (decodedSubData && decodedSubData.length > 0) {
      return decodedSubData;
    }

    return [];
  } catch (error) {
    return [];
  }
};

export function isDIY() {
  return (getUserData()?.[8] ?? '') === 'CORPORATE';
};

export function isDIFM() {
  return (getUserData()?.[8] ?? '') === 'CA';
};

export function isPlanFeatureEnabled(featureName) {
  return (getUserData()?.[16] ?? []).includes(featureName);
};

export function getPlanParameterValue(parameterName) {
  return (getUserData()?.[17]?.[parameterName] ?? 0);
};

export const PLN_FEAT_HOVER_MSG = "Your current subscription plan does not include this feature. Please upgrade your plan to keep viewing or use the feature"