import React, { useState } from "react";
import GstPortalLoginConnectorPopUp from "./GstPortalLoginConnectorPopUp";
import gstinPortalLogo from "../../images/gstin-portal-logo.png";


export default function GstPortalLoginConnector(props) {

  const { companyId, portalSyncStatus, setError } = props;

  const [showGstPortalLoginConnectorPopUp, setShowGstPortalLoginConnectorPopUp] = useState(false);
 
  return (
    <>

      {showGstPortalLoginConnectorPopUp && <GstPortalLoginConnectorPopUp
        companyId={companyId}
        show={showGstPortalLoginConnectorPopUp}
        onClose={() => {
          setShowGstPortalLoginConnectorPopUp(false);
        }}
      />}

    {portalSyncStatus==="IN_PROGRESS" ? 
      <img src={gstinPortalLogo} width="22" alt='' style={{ cursor: 'pointer' }} className="planexpiredalert"
        onClick={() => {         
            setShowGstPortalLoginConnectorPopUp(false);
            setError("Sync is in progress. Please wait until the sync completes before accessing the GST Portal.");
        }}
        />
        :
        <img src={gstinPortalLogo} width="22" alt='' style={{ cursor: 'pointer' }} className="planexpiredalert"
        onClick={() => {          
            setShowGstPortalLoginConnectorPopUp(true);
        }}
      />
    }
    </>

  )
}