import axios from "axios";
import { BASE_URL_GO } from "../app/constants";
import { handleSuccess, handleErrorWithCode } from "./responseHandler";
import { getConfig } from "./common";

export async function getEwbStatusCounts(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/status-count`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbInvoiceWiseCounts(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/invoice-wise-count`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbRateWiseTaxSummary(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/rate-wise-tax-summary`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbInvoiceWiseList(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/reco/invoice-wise`;
  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

  
export async function getEwbRateWiseAnalysisReport(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/rate-wise-analysis-report`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbPartyWiseList(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/reco/party-wise`;
  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbSectionWiseList(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/reco/section-wise-summary`;
  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCardSummaryData(postData, cardRouteKey) {
  const url = `${BASE_URL_GO}/api/v1/ewb/insight-cards/` + cardRouteKey;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getSummaryOfInsightCard(postData, cardRouteKey) {
  const url = `${BASE_URL_GO}/api/v1/ewb/insight-cards/summary/` + cardRouteKey;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


/* E-Way Bill Sync APIs */
export async function getEwayCredentialsByID(companyId = "") {
  const url = `${BASE_URL_GO}/api/v1/ewb/sync-credentials?company_id=${companyId}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function startEwayGspSync(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/start-sync-gsp`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
};

export async function eWayBillSyncStatus(company_id) {
  const url = `${BASE_URL_GO}/api/v1/ewb/sync-status?company_id=${company_id}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getAllEwbCardSummary(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/insight-cards/card-summary`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function exportR1vsEwbData(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/reco/excel-export`;

  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbInvoiceWiseSummaryList(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/reco/invoice-wise/summary`;
  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getEwbPartyWiseSummaryList(postData) {
  const url = `${BASE_URL_GO}/api/v1/ewb/reco/party-wise-summary`;
  try {
    const result = await axios.post(url, postData, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}
