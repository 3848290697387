import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import {downloadBookTemplate, importBooks, deleteBook, downloadBookPurchaseConsolidatedTemplate, getAllBooksImportStatus } from '../../api/booksAPI';
import { importConsolidatedPurchaseBooks } from '../../api/booksAPI';
import ShowAlert from '../../components/ShowAlert';
import Modal from 'react-bootstrap/Modal';
import { getFinancialMonths, getFinancialYears, validateGstNumberInFileName } from "../../utils/UtilityFunctions";
import { useSelector } from 'react-redux';
import { selectHeaderData } from '../header/headerSlice';
import ConfirmPopup from '../../components/ConfirmPopup';
import PurchaseImportStatus from '../returnDashboard/PurchaseImportStatus';
import TallySyncPopup from '../tally/TallySyncPopup';
import tellyIcon from '../../images/tally_img.png';
import importExcelIcon from '../../images/excelimg.png';
import * as XLSX from 'xlsx';
import downloadIcon from "../../images/download_down.png";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import Finlesearchicon from '../../images/finlesearchicon.png';
import delIcon from '../../images/del-icon.png';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import '../returnDashboard/ImportBook2ADataPopupV2.scss';
import './BooksDataV2.scss';
import { PLN_FEAT_HOVER_MSG, PURCHASE_RECO_IMPORT_PAN, isPlanFeatureEnabled } from '../../utils/PlanValidator';


const BooksDataV2 = (props) => {

    const { show, handleClose, company_id, filterYear:year, fetchData, postData, setSuccess, handleProcced } = props;

    const allowedExtensions = /(\.xlsx|\.xlsm|\.xls)$/i;
    const headerData = useSelector(selectHeaderData);
    const DIY = headerData.organization_type === "CORPORATE" ? true : false;
    const next_year = year ? parseInt(year) + 1 : "";
    const currYearObj = { id: `${year}`, text: `${year}-${next_year.toString().substr(2, 2)}` };
    const months = getFinancialMonths(year, headerData.registration_date);
    const fy_list = getFinancialYears(headerData.registration_date ?? '');

    const [bookList, setBookList] = useState(months);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileBookData, setFileBookData] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showPurchaseImportStatus, setShowPurchaseImportStatus] = useState(false);
    const [purchaseImportData, setPurchaseImportData] = useState([]);
    const [purchaseImportText, setPurchaseImportText] = useState([]);
    const [showValidateFileNameConfirm, setShowValidateFileNameConfirm] = useState(false);
    const [uploadBookData, setUploadBookData] = useState(false);
    const [successImport, setSuccessImort] = useState(null)
    const [booksStatusData, setBooksStatusData] = useState({});
    const [deleteFileData, setDeleteFileData] = useState({});
    const [filterYear, setFilterYear] = useState({ text: currYearObj.text, value: parseInt(currYearObj.id) });

    //const excelTabLocalData = localStorage.getItem(`tally_sync_excel_tab_${headerData?.mobile_number}`)
    //const excelTabSelected = excelTabLocalData ? JSON.parse(excelTabLocalData)?.[headerData?.gst_number] ? true : false : false;
    const [tab, setTab] = useState("default");

    const [purchaseSheetCount, setPurchaseSheetCount] = useState(null);

    const getBookList = (filterStr, year) => {
        async function fetchEverything() {
            async function fetchBookList() {
                const result = await getAllBooksImportStatus(filterStr);
                if (result.data) {
                    const rData = {};

                    result.data.map(function (ret) {
                        const booksData = {
                            failure_msg: ret?.purchase?.failure_msg ?? "",
                            import_status: ret?.purchase?.import_status ?? "",
                            imported_on: ret?.purchase?.imported_on ?? "",
                            month: ret?.month ?? "",
                            tax_period: ret?.tax_period ?? "",
                            year: ret?.year ?? "",
                        }
                        rData[ret.month] = booksData;
                        return null;
                    });
                    const months = getFinancialMonths(year, headerData.registration_date);
                    setBookList(months);
                    setBooksStatusData(rData);
                }
            }
            try {
                await Promise.all([
                    fetchBookList(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };


    const downloadBook = () => {
        async function fetchEverything() {
            async function getBookTemplate() {
                await downloadBookTemplate();
            }
            try {
                await Promise.all([
                    getBookTemplate(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    const uploadFiles = async (file, month, MonthData, sheetCount = purchaseSheetCount) => {

        let post_year = filterYear.value ? parseInt(filterYear.value) : "";

        if (parseInt(month, 10) === 1 || parseInt(month, 10) === 2 || parseInt(month, 10) === 3) {
            post_year += 1
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('company_id', company_id);
        formData.append('year', post_year);
        formData.append('month', month);

        try {
            // let result;
            // result = await importBooks(formData, book, startUploading);

            let result;
            if (sheetCount <= 1 && DIY) {
                result = await importConsolidatedPurchaseBooks(formData, MonthData, startUploading);
            } else {
                result = await importBooks(formData, MonthData, startUploading);
            }

            updateBookList(MonthData, 'add');
            let filterstr = `company_id=${company_id}&year=${parseInt(filterYear.value)}`
            getBookList(`${filterstr}`, filterYear.value);

            setPurchaseImportData(result.data);
            setPurchaseImportText(result.message);
            setShowPurchaseImportStatus(true);
        } catch (err) {
            if (err?.code === 504) {
                updateBookList(MonthData, 'remove');

                setSuccessImort("Your imported file is being processed. Please refresh after sometime to view the updated data.");
                // document.getElementById('rm_purchsae_file_gstr2').click();
            } else {
                setError(err?.message);
            }
            updateBookList(MonthData, '');
            setLoading(false);
        } 
        //setFileBookData(false);
    };

    const removeBookFile = async (month) => {
        setShowConfirm(false);
        setLoading(true);
        let post_year = filterYear.value ? parseInt(filterYear.value) : "";

        if (parseInt(month, 10) === 1 || parseInt(month, 10) === 2 || parseInt(month, 10) === 3) {
            post_year += 1
        }

        const apiPayload = {
            "company_id": company_id,
            "return_periods": [
                {
                    "year": parseInt(parseInt(post_year)),
                    "month": parseInt(month)
                }
            ]
        }
        try {
            const result = await deleteBook(apiPayload);
            updateBookList(fileBookData, 'remove');

            setSuccessImort(result.message)
        } catch (err) {
            setError(err.message);
        } finally {
            setDeleteFileData({});
            setTimeout(() => {
                window.location.reload();
            }, 1000)

        }
        setLoading(false);
        setFileBookData(false);
    };

    const startUploading = (book, progress) => {
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === book.month && bookdata.year === book.year) {
                bookdata.file_uploading = progress;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };

    const updateBookList = (book, type) => {
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === book.month && bookdata.year === book.year) {
                if (type === 'add') {
                    bookdata.imported_on = moment().format('DD/MM/yyyy');
                    bookdata.import_status = 'PENDING';
                }
                if (type === 'remove') {
                    bookdata.imported_on = moment().format('DD/MM/yyyy');
                    bookdata.import_status = 'PENDING';
                }
                bookdata.file = null;
                bookdata.file_uploading = 0;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };

    const browseFile = (book) => {
        document.getElementById('book_file').click();
        book.file_uploading = 0;
        setFileBookData(book);
    };

    const onFileSelected = (event) => {
        const file = event.target.files[0];
        let valid = false;
        if (allowedExtensions.exec(file.name)) {
            valid = true;
        } else {
            setError('Import Format Supported: .xls, .xlsx, xlsm');
            valid = false;
            setFileBookData(false);
        }
        event.target.value = null;
        const new_bookList = bookList.map((bookdata) => {
            if (bookdata.month === fileBookData.month && bookdata.year === fileBookData.year) {

                if (file) {
                    const fileReader = new FileReader();
                    setLoading(true)

                    fileReader.onload = (event) => {
                        const buffer = event.target?.result;
                        const workbook = XLSX.read(buffer, { type: 'buffer' });
                        const sheets = workbook.SheetNames;
                        setPurchaseSheetCount((sheets.length - 1)); // minus count of default mastersheet 
                        if (valid) validateFileNameAndUpload(file, bookdata.value, bookdata, (sheets.length - 1))
                        else setLoading(false);
                    };
                    // Read the file as an array buffer
                    fileReader.readAsArrayBuffer(file);
                }
                bookdata.file = valid ? file : null;
            }
            return bookdata;
        });
        setBookList(new_bookList);
    };

    const removeFile = (month) => {
        setDeleteFileData({ month: month });
        setShowConfirm(true);
    }

    const validateFileNameAndUpload = (month_file, month_value, month, sheetCount) => {
        const validateFile = validateGstNumberInFileName(month_file.name ?? '', headerData.gst_number ?? '');
        if (validateFile === false) {
            setUploadBookData({ month_file: month_file, month_value: month_value, month: month })
            setShowValidateFileNameConfirm(true)
            setLoading(false)
        } else {
            uploadFiles(month_file, month_value, month, sheetCount);
        }
    }



    const changeYearFilter = (value) => {
        setFilterYear(value);
    };

    const downloadBookGSTR2Lite = () => {
        async function fetchEverything() {
            async function getBookTemplate() {
                await downloadBookPurchaseConsolidatedTemplate();
            }
            try {
                await Promise.all([
                    getBookTemplate(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        fetchEverything();
    };

    useEffect(() => {
        let filterstr = `company_id=${company_id}&year=${year}`
        getBookList(`${filterstr}`, year);
        //eslint-disable-next-line
    }, [company_id, year]);

    return (
        <>

            {loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={null}
                success={successImport}
                onClose={() => { setSuccessImort(null); }}
            />
            {
                showValidateFileNameConfirm &&
                <ConfirmPopup
                    show={showValidateFileNameConfirm}
                    handleProceed={() => {
                        uploadFiles(uploadBookData.month_file, uploadBookData.month_value, uploadBookData.month)
                        setShowValidateFileNameConfirm(false);
                    }}
                    confirmText={`It seems that the file you are trying to import belongs to a different GST Number. Do you still wish to continue importing the file ?`}
                    confirmButtonText="Upload"
                    handleClose={() => {
                        setShowValidateFileNameConfirm(false);
                    }}
                />
            }
            {showPurchaseImportStatus && (
                <PurchaseImportStatus
                    show={showPurchaseImportStatus}
                    onClose={() => {
                        setShowPurchaseImportStatus(false);
                        setPurchaseImportData([]);
                        if (fetchData) fetchData(postData);
                        if (handleProcced) handleProcced('', false)
                        handleClose();
                        window.location.reload();
                    }}
                    handleSucessProcees={() => {
                        setShowPurchaseImportStatus(false);
                        setPurchaseImportData([]);
                        if (fetchData) fetchData(postData);
                        if (handleProcced) handleProcced('')
                        handleClose();
                        window.location.reload();
                    }}
                    onReImport={() => {
                        setShowPurchaseImportStatus(false);
                        setPurchaseImportData([]);
                        document.getElementById('book_file').click();
                    }}
                    data={purchaseImportData}
                    purchaseImportText={purchaseImportText}
                />
            )}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                id="gstr1leftsidem"
                className="gstr1leftsidem"
            >

                <Modal.Header closeButton className="gstr1leftsideh">
                    <h4 className="modal-title">Import Books Data</h4>
                    {tab === "excel" ? <div style={{ position: "absolute", right: "4rem" }}>
                        <span style={{ paddingRight: '10px', color: '#4d4040' }}>
                            {'F.Y.'}
                        </span>
                        <span className="loaderboxlpad">
                            <DropDownList
                                data={fy_list}
                                className="header_select headerselect"
                                popupSettings={{ className: 'headerselectoption' }}
                                textField="text"
                                value={filterYear}
                                onChange={(e) => {
                                    changeYearFilter(e.target.value);
                                    let filterstr = `company_id=${company_id}&year=${parseInt(e?.target?.value?.value ?? "")}`
                                    getBookList(`${filterstr}`, parseInt(e?.target?.value?.value ?? ""));
                                }}
                                dataItemKey="value"
                                id="yearDropDownPopup"
                            />
                        </span>
                    </div>
                        : null}

                </Modal.Header>

                <Modal.Body>

                    <ShowAlert
                        success=""
                        error={error}
                        onClose={() => {
                            if (setSuccess) setSuccess(null);
                            if (setError) setError(null);
                        }}
                        headerStyle={{
                            position: 'absolute', top: 0, left: 0, width: '100%', fontSize: '13.5px',
                        }}
                    />
                    <ConfirmPopup
                        show={showConfirm}
                        handleProceed={() => removeBookFile(deleteFileData?.month?.value)}
                        confirmText={`Are you sure that you want to delete the uploaded books data for the period ${fileBookData.tax_period}? Please note that this process is irreversible. Click Delete button to continue.`}
                        confirmButtonText="Delete"
                        handleClose={() => {
                            setFileBookData(false);
                            setShowConfirm(false);
                        }}
                    />
                    <input type="file" name="book_file" id="book_file" accept=".xls, .xlsx, .xlsm" onChange={onFileSelected} style={{ display: 'none' }} />

                    <div className="row">
                        <div className="col-md-12">
                            <div className="recodash_importbooksdata_popup">
                                <div class="row mb-2">
                                    <div class="col-md-5 text-center" onClick={() => { setTab('tally') }}>
                                        <div class="card recodash_importbooksdata_tallybox">
                                            <h4 class="recodash_tallywithconfig_titlefs">
                                                Configure with
                                            </h4>
                                            <span class="tally_imgbox">
                                                <img src={tellyIcon} width="80" alt='' />
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <h4 class="importtextortextfs">
                                            OR
                                        </h4>
                                    </div>
                                    <div class="col-md-5 text-center" onClick={() => { setTab('excel') }}>
                                        <div class="card recodash_importbooksdata_excelbox">
                                            <span class="excelbox_imgbox">
                                                <img src={importExcelIcon} width="60" alt='' />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {(tab === "tally") &&
                                    <TallySyncPopup companyId={company_id} />
                                }

                                {
                                    (tab === "excel") &&
                                    <>

                                        <div className='row mb-2'>
                                            <div class="col-lg-12 pt-2">
                                                <div class="row mt-2">
                                                    <div class="col-md-1">
                                                        <img src={downloadIcon} alt='download' width="22" class="cursorpointer" />
                                                    </div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-default recodash_gstr1blanktemp_btn" onClick={downloadBook}>
                                                            Blank Template
                                                        </button>
                                                    </div>
                                                    {
                                                        (DIY) ? 
                                                        <>
                                                            <div class="col-md-1 text-center">
                                                                <span class="importOR_textfs">
                                                                    OR
                                                                </span>
                                                            </div>
                                                            
                                                            <div class="col-md-5 text-right">
                                                                {isPlanFeatureEnabled(PURCHASE_RECO_IMPORT_PAN) ? 
                                                                <button class="btn btn-default recodash_gstr1blanktemp_btn" onClick={
                                                                    downloadBookGSTR2Lite}>
                                                                    Template with PAN Level
                                                                </button> :
                                                                <button class="btn btn-default recodash_gstr1blanktemp_btn  disabled" title={PLN_FEAT_HOVER_MSG} onClick={()=>setError(PLN_FEAT_HOVER_MSG)}>
                                                                    Template with PAN Level
                                                                </button> }

                                                            </div>
                                                        </> : ""}
                                                    
                                                </div>

                                                <div className="card gstr1card mt-2" id="impbooksdata_tabbox_tally_01">

                                                    <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                                        <Tooltip anchorElement="pointer" position="top">
                                                            <table className="table table-striped recodash_gstr1_table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="120">Tax Period</th>
                                                                        <th width="250">Last import on </th>
                                                                        <th width="200">Action</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody className="">
                                                                    {bookList.map((month) => {
                                                                        if (month.disable) return true;
                                                                        if (!booksStatusData[month.value]) return null;
                                                                        return (
                                                                            <tr key={month.value}>
                                                                                <td width={120} className="recodash_gsr1tb_textclr_coral text-center">{month.text}</td>
                                                                                <td className='text-center' width={250}>
                                                                                    {(month.file)
                                                                                        ? (
                                                                                            <>
                                                                                                <span>
                                                                                                    {(month.file_uploading && month.file_uploading > 0) ?
                                                                                                        month.file_uploading === 100 ?
                                                                                                            <>
                                                                                                                <ChunkProgressBar
                                                                                                                    min={1} max={50}
                                                                                                                    value={null}
                                                                                                                    chunkCount={10}
                                                                                                                    style={{ width: '100%' }}
                                                                                                                />
                                                                                                                <span className="processing_text">Processing</span>
                                                                                                            </>
                                                                                                            :
                                                                                                            <ProgressBar
                                                                                                                value={month.file_uploading}
                                                                                                                style={{ width: '100%' }}
                                                                                                                labelPlacement="center"
                                                                                                                label={(props) => (
                                                                                                                    <strong> Uploading..{props.value}%
                                                                                                                    </strong>
                                                                                                                )}
                                                                                                            />

                                                                                                        :
                                                                                                        <>
                                                                                                            <span className='recodash_gsr1tb_textclr_coral'>
                                                                                                                {
                                                                                                                    month?.file?.name?.length > 18
                                                                                                                        ? <span title={month.file.name}>{`${month.file.name.substr(0, 18)}...`}</span>
                                                                                                                        : month.file.name
                                                                                                                }
                                                                                                            </span>
                                                                                                        </>
                                                                                                    }
                                                                                                </span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <span>

                                                                                                {booksStatusData?.[month.value]?.imported_on &&
                                                                                                    <>
                                                                                                        <span>{booksStatusData?.[month?.value]?.imported_on ?? ""}</span>
                                                                                                    </>
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    <span className="browdeliconaligright">

                                                                                        <>
                                                                                            {booksStatusData[month.value].imported_on && (
                                                                                                <span className="browsegstr1delicon" onClick={() => removeFile(month)}>
                                                                                                    <img src={delIcon} alt="" className="browse_file rdImgSz" />
                                                                                                </span>)}
                                                                                            <span className="browsegstr1" onClick={() => browseFile(month)}>
                                                                                                <img src={Finlesearchicon} alt="" className="browse_file" />
                                                                                            </span>
                                                                                        </>

                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </Tooltip>
                                                    </div>

                                                    <p className="importformat_textfs mb-1">
                                                        Import Format Supported: .xlsx, .xls, .xlsm
                                                    </p>
                                                    <p className="recodash_notetextfs">
                                                        Note: We support Govt. and all Accounting Soft. Templates
                                                    </p>

                                                    {/* <div className="impoforsuptext"> Import Format Supported: .xlsx, .xls, .xlsm</div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};
export default BooksDataV2;
