import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import NumericInput from "../../components/NumericInput";
import { selectHeaderData } from "../header/headerSlice";
import { verify2ADataV2 } from "../../api/2adataAPI";
import UpdateCredentialsV2 from "./UpdateCredentialsV2";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import OtpVerifiyForCaptcha from "../../components/profile/OtpVerifiyForCaptcha";
import {
  getCaptchaFromConnector,
  //verifyGSTPortalLogin,
  //verifyGSTPortalLoginWithOTP,
  startSyncAction,
  verifyGSTPortalLoginV2
} from "../../api/localConnectorAPI";
import { getCompanyDetailsByID } from "../../api/companyAPI";
import { CONNECTOR_ERRORS_MAPPING, HEADER_CONNECTOR_DIGEST_KEY } from "../../app/constants";
import { updateCompanyDetails } from "../../api/companyAPI";
import { serverLoginRequestForConnectorLogin } from "../../api/serverConnectorAPI";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes  } from '@fortawesome/free-solid-svg-icons';

const SyncProcessFailByConnector = ({ history, ...props }) => {
  const {
    company_id,
    handleClose,
    handleSwitchBubbleToDefault,
    refreshSupplierList,
    syncStatusFailureMsg,   
    port,
    //setParentError,
  } = props;

  const headerData = useSelector(selectHeaderData);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaImg, setCaptchaImg] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [companyGstNumber, setCompanyGstNumber] = useState("");
  const [gstportalUsername, setGstportalUsername] = useState("");
  const [gstportalPassword, setGstportalPasword] = useState("");
  const [getCaptchaData, setGetCaptchaData] = useState({});
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [otpRequired, setOtpRequired] = useState(false)
  const [captchaRef, setCaptchaRef] = useState(null);

  const getCredentials = () => {
    async function fetchEverything() {
      try {
        const result = await getCompanyDetailsByID(company_id);
        setGstportalUsername(result?.data?.gstportal_username);
        setGstportalPasword(result?.data?.gstportal_password);
        setOpenSetPassword(!result?.data?.gstportal_isverified);
        setCompanyGstNumber(result?.data?.gst_number)
        getCaptcha();
      } catch (err) {
        setError(err.message);
      }
    }
    fetchEverything();
  }

  const getCaptcha = () => {
    async function fetchEverything() {
      try {
        const result = await getCaptchaFromConnector(port);
        setCaptchaImg(result.data.captcha_image);
        setCaptchaRef(result.data.captcha_ref);
        setGetCaptchaData(result.data);
        setCaptcha("");
      } catch (err) {
        if (Array.isArray(err?.message) && err?.message?.[0] !== undefined) {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message[0]] ?? CONNECTOR_ERRORS_MAPPING['traces.unknown_error']);
        } else {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['traces.unknown_error']);
        }
      } finally {
        setCaptchaLoading(false);
        setCaptcha('');
        setLoadingFirst(false);
      }
    }
    setCaptchaLoading(true);
    fetchEverything();
  };

  const loadCaptcha = () => {
    setError(null);
    setCaptchaLoading(true);
    getCaptcha();
  };

  const verifyCatpcha = (props) => {
    if (!props?.otp) {
      if (captcha.length === 0) {
        setError("Please enter captcha!");
        return;
      }
      if (captcha.length < 6) {
        setError("Please enter valid captcha!");
        return;
      }
    }
    const data = {
      captcha_ref: captchaRef,
      gstportal_username: gstportalUsername,
      gstportal_password: gstportalPassword,
      company_gst_number: companyGstNumber,
      ...(props?.otp ? { otp: props.otp } : { captcha_text: captcha }),
      //captcha_text: captcha,
    };

    async function fetchEverything() { 
      try {
        const serverRequestPayload = {
          company_gst_number: data?.company_gst_number,
          captcha_ref: data?.captcha_ref,
          captcha_text: data?.captcha_text,
        }
        //call server api to get data for connector login request
        const result = await serverLoginRequestForConnectorLogin(serverRequestPayload);
        const responseData = result.data ?? null;
        const responseHeader = result.headers ?? null;
        if (Number(responseData.status) === 1) {
          const connectorRequest = {
            "data": responseData.data
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

          try {
            const response = await verifyGSTPortalLoginV2(port, connectorRequest, extraHeader);
            if (Number(response?.status ?? 0) === 1) {
              startSyncing();
            } else {
              handleClose(false);
            }
            setError(null);
            setOtpRequired(false)
          } catch (err) {
            setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
            setCaptcha("");
            getCaptcha();
            if (err?.message === 'gst.invalid_credentials') {
              updateCompanyData();
            }
          }
        }       

      } catch (err) {

        if (err?.status === 423) {
          setOtpRequired(true)
        } else {
          setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
          setCaptcha("");
          getCaptcha();
          if (err?.message === 'gst.invalid_credentials') {
            updateCompanyData();
          }
        }

      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const updateCompanyData = async () => {
    const data = {
      company_id: company_id,
      gstportal_isverified: false
    };
    try {
      await updateCompanyDetails(data);
      setTimeout(() => { window.location.reload() }, 500)
    } catch (err) {
      // console.error('Error in updating: ', err);
    }
  }

  const startSyncing = () => {
    async function fetchEverything() {

      try {
        const result = await verify2ADataV2({ captcha_ref: captchaRef, hard_refresh: 1, company_gst_number: companyGstNumber });
        const responseData = result.data ?? null;
        const responseHeader = result.headers ?? null;

        if (Number(responseData.status) === 1) {
          const connectorRequest = {
            "data": responseData.data
          };
          let extraHeader = {};
          extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;
          try {
            const response = await startSyncAction(port, connectorRequest, extraHeader);
            if (Number(response?.status ?? 0) === 1) {
              let return_filling_status_data = localStorage.getItem(
                "return-filling-status-data-v4"
              );
              return_filling_status_data = return_filling_status_data
                ? JSON.parse(return_filling_status_data)
                : [];
              let current_filling_return_data = return_filling_status_data.filter(
                (return_data, index) => {
                  return return_data.gst_number !== headerData.gst_number;
                }
              );
              localStorage.setItem(
                "return-filling-status-data-v4",
                JSON.stringify(current_filling_return_data)
              );

              handleClose(false);
              refreshSupplierList('Syncing process started successfully', true);
            }
          } catch (err) {
            setError(CONNECTOR_ERRORS_MAPPING[err?.message] ?? CONNECTOR_ERRORS_MAPPING['gst.unknown_error']);
          }

        }

      } catch (err) {
        setError(err?.data?.errors[0])
        setCaptcha("")
      }
    }
    fetchEverything()
  }



  const handleCloseOtp = () => {
    setOtpRequired(false);
    getCaptcha()
  }

  useEffect(() => {
    getCredentials();
    document.getElementById("captcha").focus();
    if (syncStatusFailureMsg !== "") {
      setError(syncStatusFailureMsg);
    }
    //eslint-disable-next-line
  }, [company_id]);

  return (
    <>
      {loadingFirst ? <LoadingIndicator /> : null}
      
        {openSetPassword ? (
          handleSwitchBubbleToDefault() // switch to default modal popup
        ) : (
          <>
           
           <div className="gstheader_synccardhoverbox header_syncbubble_clickhidebox">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="media">
                  <div className="media-body">
                    <p className="gstheader_synccard_contentbox">
                      Oops! It seems like the sync failed. This can happen due to congestion at GSTN Portal or logging to GSTN Portal during the sync..
                    </p>
                    <p className="gstheader_synccard_gstinportal_textfs">Please enter captcha to restart</p>
                  </div>
                  <div className="gstheader_synccard_closeboxicon ml-1" onClick={handleClose}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                    <span className="">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="card gstheader_synccardboxcetcha">
                  <div className="row">
                    <div className="form-group col-md-7 mb-0">
                      <div className="gstheader_synccard_captchaimg captcha_img">
                        {!captchaLoading ? (
                          <img
                            src={`data:image/png;base64, ${captchaImg}`}
                            alt=""
                          />
                        ) : (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </div>
                    
                      <div className=" form-group col-md-5" >
                        <NumericInput
                          className="form-control"
                          style={{boxShadow:'none', fontSize:'0.72rem'}}
                          id="captcha"
                          placeholder="Enter Captcha"
                          value={captcha}
                          maxLength={6}
                          onChange={(event) => setCaptcha(event.target.value)}
                          autoComplete="new-password"
                        />
                      </div>
                    
                    <div className="col-sm-12 text-left">
                      <p className="gstheader_synccard_captchatextfs">
                        <small style={{ fontSize: '12px' }}>
                          Can't read the captcha?
                          <Button
                            variant="link"
                            onClick={() => loadCaptcha()}
                            style={{ margin: "-9px", fontSize: 12, verticalAlign: 'baseline' }}
                          >
                            Click here
                          </Button>
                          to refresh
                        </small>
                      </p>
                    </div>
                    <div className="col-sm-12 text-left">
                      <p className="gstheader_synccard_progresstextfs mb-0">
                        Do not login to GSTN Portal during this progress.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center pt-2" style={{fontSize:'11px', fontWeight:'normal'}}>
                {error ? <Alert variant="danger">{error}</Alert> : null}
                <Button
                  className="btn btn-default gstheader_synccard_goit_btn bookVerifyBtn"
                  id="zps_proceed_portal"
                  onClick={verifyCatpcha}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>

              </div>
            </div>
          </div>
          </>
        )}
    
      {otpRequired && <OtpVerifiyForCaptcha handleClose={handleCloseOtp} callFunc={verifyCatpcha} error={error} setError={setError} />}

    </>
  );
};

export default SyncProcessFailByConnector;
