import React, { useEffect, useState } from 'react';
import { getEwayCredentialsByID, startEwayGspSync } from '../../api/EWayBillsAPI';
import ShowAlert from '../../components/ShowAlert';
import GSPCredentialsPopup from './GSPCredentialsPopup';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';


const SyncData = (props) => {

  const { company_id, eWaySyncStatusFailureMsg, closePopup, enableEwbCredsCredntials = false, setEnableEwbCredsCredntials } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [gspErr, setGspErr] = useState(false);

  const [isGspVerified, setIsGspVerified] = useState(null);
  const [gspUserName, setGspUserName] = useState(null);
  const [gspUserPassword, setGspUserPassword] = useState(null);

  const [isEwbVerified, setIsEwbVerified] = useState(null);
  const [ewbUserName, setEwbUserName] = useState(null);
  const [ewbUserPassword, setEwbUserPassword] = useState(null);

  const [showGspCredsPopup, setShowGspCredsPopup] = useState(false);
  const [showEwbCredPopup, setShowEwbCredPopup] = useState(false);


  const getEwaySyncCredentials = async () => {
    setLoading(true);
    try {
      const result = await getEwayCredentialsByID(company_id);

      if (result?.data) {

        setIsGspVerified(result?.data?.gsp_isverified ?? null);
        setGspUserName(result?.data?.gsp_username ?? "");
        setGspUserPassword(result?.data?.gsp_password ?? "");

        setIsEwbVerified(result?.data?.ewb_isverified ?? null);
        setEwbUserName(result?.data?.ewb_user_name ?? "");
        setEwbUserPassword(result?.data?.ewb_password ?? "");

        if (result?.data?.ewb_isverified) {
          setShowEwbCredPopup(true);
        }
        setShowGspCredsPopup(true);

      } else {
        setShowGspCredsPopup(true);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const startGspSync = async (companyId, gspUsername, gspPassword) => {
    setLoading(true);

    const postData = {
      company_id: companyId,
      gsp_username: gspUsername,
      gsp_password: gspPassword,
    }

    try {
      await startEwayGspSync(postData);
      window.location.reload();
      // When the user starts the sync process for the first time, an error message appears below the cards 
      // on the eWayBillSummary page (e.g., "Enter credentials to view data").
      // Since the cards are displayed in a different component, we used window.location.reload 
      // to remove the error once the sync starts and refresh the data.
    } catch (err) {
      setLoading(false);
      setGspErr(err.message);
    }
  };


  useEffect(() => {
    getEwaySyncCredentials();

    if (eWaySyncStatusFailureMsg !== "") {
      setError(eWaySyncStatusFailureMsg);
    }
    //eslint-disable-next-line
  }, [company_id]);



  return (
    <>

      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        error={error || gspErr}
        onClose={() => { setError(null); setGspErr(null); }}
      />

      {showGspCredsPopup &&
        <GSPCredentialsPopup
          show={showGspCredsPopup}
          handleClose={() => {
            setShowGspCredsPopup(false);
            closePopup();
          }}
          company_id={company_id}
          startGspSync={(companyId, username, password) => {
            startGspSync(companyId, username, password)
            //reset enable EWB Credentials status
            if (setEnableEwbCredsCredntials) {
              setEnableEwbCredsCredntials(false)
            }
          }
          }
          gspUserName={gspUserName}
          gspUserPassword={gspUserPassword}
          ewbUserName={ewbUserName}
          ewbUserPassword={ewbUserPassword}
          isEwbVerified={isEwbVerified}
          isGspVerified={isGspVerified}
          showEwbCredPopup={showEwbCredPopup}
          gspErr={gspErr}
          setGspErr={setGspErr}
          enableEwbCredsCredntials={enableEwbCredsCredntials}
        />
      }

    </>
  )

};

export default SyncData;

